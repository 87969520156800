// 同步node中间层services/trend/utils.js 的逻辑

const trendGrowthImageAB = (GB_cssRight) => {
  if (GB_cssRight) {
    return {
      BIG: '//shein.ltwebstatic.com/svgicons/2024/06/12/17181987882931096159.svg',
      SMALL: '//shein.ltwebstatic.com/svgicons/2024/06/12/17181989402329112847.svg',
      UP_SMOOTH: '//shein.ltwebstatic.com/svgicons/2024/06/12/17181990073141630223.svg',
      UP_DOWN: '//shein.ltwebstatic.com/svgicons/2024/06/12/1718199035342534141.svg',
    }
  }

  return {
    BIG: '//shein.ltwebstatic.com/svgicons/2024/06/12/17181923691006410201.svg',
    SMALL: '//shein.ltwebstatic.com/svgicons/2024/06/12/17181946453465679361.svg',
    UP_SMOOTH: '//shein.ltwebstatic.com/svgicons/2024/06/12/1718193107343643378.svg',
    UP_DOWN: '//shein.ltwebstatic.com/svgicons/2024/06/12/17181960491892442858.svg',
  }
}

const trendGrowthImageC = (GB_cssRight) => {
  if (GB_cssRight) {
    return {
      BIG: '//shein.ltwebstatic.com/svgicons/2024/06/14/17183520321527006103.svg',
      SMALL: '//shein.ltwebstatic.com/svgicons/2024/06/14/17183520324153440995.svg',
      UP_SMOOTH: '//shein.ltwebstatic.com/svgicons/2024/06/14/17183520324124808616.svg',
      UP_DOWN: '//shein.ltwebstatic.com/svgicons/2024/06/14/17183520323190692426.svg',
    }
  }

  return {
    BIG: '//shein.ltwebstatic.com/svgicons/2024/06/14/1718351124632724481.svg',
    SMALL: '//shein.ltwebstatic.com/svgicons/2024/06/14/17183513131617037059.svg',
    UP_SMOOTH: '//shein.ltwebstatic.com/svgicons/2024/06/14/17183513133768465918.svg',
    UP_DOWN: '//shein.ltwebstatic.com/svgicons/2024/06/14/17183513132082909605.svg',
  }
}

// 趋势频道--趋势卡片增速图
const getTrendsGrowthImage = (styleType = 'A', GB_cssRight = false) => {
  // 根据 TrendsCardStyle 值取不同的吸顶图片
  return ['A', 'B'].includes(styleType) ? trendGrowthImageAB(GB_cssRight) : trendGrowthImageC(GB_cssRight)
}

const GROWTH_TYPE = {
  BIG_BIG: 'BIG_BIG',                   // 图片:大幅度 - 类型:大幅度
  SMALL_SMALL: 'SMALL_SMALL',           // 图片:小幅度 - 类型:小幅度 
  UP_SMOOTH_SMALL: 'UP_SMOOTH_SMALL',   // 图片:先涨到平稳 - 类型:小幅度  
  UP_DOWN_PEAKED: 'UP_DOWN_PEAKED',     // 图片:先涨后跌- 类型: 持平 
  EMPTY: 'EMPTY',                       // 不展示
}

// 增速幅度类型
const getGrowthType = ({ bffImgType }) => {
  try {
    // 中间层映射： 增速图片类型，大幅度：1，小幅度：2，先涨后平稳：3，先涨后跌：4
    const bff2webMap = [
      GROWTH_TYPE.EMPTY,
      GROWTH_TYPE.BIG_BIG,
      GROWTH_TYPE.SMALL_SMALL,
      GROWTH_TYPE.UP_SMOOTH_SMALL,
      GROWTH_TYPE.UP_DOWN_PEAKED,
    ]
    return bff2webMap[bffImgType] ?? GROWTH_TYPE.EMPTY
  } catch (error) {
    return GROWTH_TYPE.SMALL_BREAKOUT
  }
}

// 处理趋势标签值
const handelGrowthLabelUtils = ({ growthLabel = {}, styleType, GB_cssRight = false }) => {

  const { growthImgType: bffImgType, text, growthRate } = growthLabel || {}
  const GROWTH_IMAGE = getTrendsGrowthImage(styleType, GB_cssRight)

  const baseData = {
    [GROWTH_TYPE.BIG_BIG]: {
      growthType: 'big_trending',
      growthImage: GROWTH_IMAGE.BIG,
      growthImageType: 'BIG',
    },   
    [GROWTH_TYPE.SMALL_SMALL]: {
      growthType: 'small_trending',
      growthImage: GROWTH_IMAGE.SMALL,
      growthImageType: 'SMALL',
    },  
    [GROWTH_TYPE.UP_SMOOTH_SMALL]: {
      growthType: 'small_trending',
      growthImage: GROWTH_IMAGE.UP_SMOOTH,
      growthImageType: 'UP_SMOOTH',
    },
    [GROWTH_TYPE.UP_DOWN_PEAKED]: {
      growthType: 'peaked',
      growthImage: GROWTH_IMAGE.UP_DOWN,
      growthImageType: 'UP_DOWN',
    },
    [GROWTH_TYPE.EMPTY]: {
      growthType: '',
      growthImage: '',
      growthLanKey: '',
    },
  }

  // 不受增速系数影响，使用原始值
  const type = getGrowthType({ bffImgType })
  const { growthType, growthImage, growthImageType } = baseData[type] || {}

  return {
    ...growthLabel,
    growthRate,
    growthType,
    growthImage,
    languageText: text,
    growthImageType,
  }
}

export {
  handelGrowthLabelUtils
}
